.method-modal-container {
    position: absolute;
    width: 50vw;
    height: 32vh;
    top: 34%;
    left: 25%;
    z-index: 11;
    background-color: $lightText;
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .modal-header {
        color: $blackText;
        background-color: $backgroundLight;
        font-weight: $bold;
        border: none !important; }
    .modal-body {
        border: none !important;
        padding: 3rem 5rem 2rem;
        background-color: $lightText;
        color: $blackText;
        font-weight: $regular;
        p {
            &:nth-child(2) {

                margin-bottom: 0; } } }
    .modal-footer {
        padding: 1.5rem;
        background-color: $lightText;
        border: none !important; } }


@media screen and ( max-width: 1367px ) {
    .method-modal-container {
        width: 46vw;
        height: 22vh;
        top: 25%;
        left: 27%;
        .modal-body {
            padding: 2.5rem 5rem 2rem; }
        .modal-footer {
            padding: 1rem; } } }

@media screen and ( max-width: 1025px ) {
    .method-modal-container {
        width: 50vw;
        height: 20vh;
        top: 30%;
        left: 25%;
        .modal-body {
            padding: 2rem 4rem 1rem; }
        .modal-footer {
            padding: 1rem; } } }

@media screen and ( max-width: 768px ) {
    .method-modal-container {
        width: 50vw;
        height: 20vh;
        top: 23%;
        left: 25%;
        .modal-body {
            padding: 2rem 2rem 1rem; }
        .modal-footer {
            padding: 1rem; } } }
