#panel-edit-modal {
    .modal-header {
        color: $blackText;
        background-color: $backgroundLight;
        font-weight: $bold;
        border: none !important;
        height: fit-content; }
    .modal-body {
        padding: 2rem;
        background-color: $lightText;
        color: $blackText;
        font-weight: $regular;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
        align-items: flex-start;
        border: none !important; }
    .modal-footer {
        padding: 1.5rem;
        background-color: $lightText;
        border: none !important; } }

@media screen and ( max-width: 1367px ) {
    #panel-edit-modal {
        .modal-body {
            padding: 1rem 1.5rem 1.5rem 1.5rem; }
        .modal-footer {
            padding: 0.5rem 1.5rem; } } }
