// Colors
$backgroundDark: #343534;
$backgroundLight: #f5f5f5;
$backgroundPanel: #ebebeb;

$grayMisc: #B8B8B8;

$lightText: #fcfcfc;
$blackText: #2D2D2D;

///

$customerBrand: #FF6C0E;
$customerBrand-disabled: #86807c;
$customerBrand-hovered: #ca550c;
$customerBrand-active: #a1450c;

$customerBrand-secondary: #00B2E3;

$shopify: #95E1BF;
$shopify-disabled: #b2e0cb;
$shopify-hovered: #7ebea2;
$shopify-active: #659781;

$tiendanube: #00B2E3;
$tiendanube-disabled: #5fc6e2;
$tiendanube-hovered: #009ac5;
$tiendanube-active: #006a88;

$vtex: #FF8672;
$vtex-disabled: #fc9c8c;
$vtex-hovered: #cf6d5b;
$vtex-active: #aa5a4c;

///

$navItems: #797979;

$alertErrorText: #FF1515;
$backgroundError: #FFF3F3;

$alertSuccessText: #04CE6D;
$alertSuccessBackground: #E6FAF0;

$warningSuccessText: #8f860e;
$warningSuccessBackground: #fffab3;

$badge-blue: hsla(213, 89%, 58%, 1);
$badge-green: hsla(151, 96%, 41%, 1);
$badge-yellow: hsla(9, 100%, 72%, 1);
$badge-red: hsla(9, 100%, 72%, 1);

$restoreBtn: #71c59f;
$restoreBtn-hovered: #58a583;
$restoreBtn-activated: #437e63;

// $cancelBtn: rgba(245, 47, 65, 0.5)
// $cancelBtn-hovered: rgba(216, 42, 57, 0.5)
// $cancelBtn-activated: rgba(151, 29, 39, 0.5)

$fulfillBtn: #FF8672;
$fulfillBtn-hovered: #ce6c5b;
$fulfillBtn-activated: #a7584a;

$payBtn: #24A0ED;
$payBtn-hovered: #1e86c7;
$payBtn-activated: #196b9e;

// Fonts

@font-face {
    font-family: 'Mulish';
    src: local('Mulish'), url(./fonts/Mulish-Bold.ttf) format('truetype');
    font-weight: 700; }

@font-face {
    font-family: 'HeptaSlab';
    src: local('HeptaSlab'), url(./fonts/HeptaSlab-Semibold.ttf) format('truetype');
    font-weight: 600; }

@font-face {
    font-family: 'Mulish';
    src: local('Mulish'), url(./fonts/Mulish-Medium.ttf) format('truetype');
    font-weight: 500; }

@font-face {
    font-family: 'Mulish';
    src: local('Mulish'), url(./fonts/Mulish-Regular.ttf) format('truetype');
    font-weight: 400; }

@font-face {
    font-family: 'Mulish';
    src: local('Mulish'), url(./fonts/Mulish-Light.ttf) format('truetype');
    font-weight: 300; }

@font-face {
    font-family: 'Mulish';
    src: url("./fonts/Mulish-Bold.ttf");
    src: url("./fonts/Mulish-Medium.ttf");
    src: url("./fonts/Mulish-Regular.ttf");
    src: url("./fonts/Mulish-Light.ttf"); }

@font-face {
    font-family: 'HeptaSlab';
    src: url("./fonts/HeptaSlab-Semibold.ttf"); }

$main-font: 'Mulish';
$titles-font: 'HeptaSlab';
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
