.white-backdrop-fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: rgba(45, 45, 45, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    .confirmDelete-content-container {
        background-color: white;
        border-radius: 16px;
        padding: 2.5rem 3.75rem;
        position: relative;
        text-align: center;
        transform: translateX(8vw); // to account for sidebar width
        width: clamp(580px, 35vw, 100%);
        .closeButton {
            border: none;
            background: none;
            position: absolute;
            right: 20px;
            top: 20px;
            padding: 0;
            font-weight: $bold;
            width: 16px;
            height: 16px; }
        h2 {
            font-size: 1.5rem;
            font-weight: $bold;
            text-align: center;
            margin-top: 30px; }
        .confirmDelete-btns {
            column-gap: 2rem;
            .confirmCancel-btn,
            .confirmDelete-btn {
                border-color: transparent;
                font-weight: $bold;
                padding-block: .5rem; }
            .confirmCancel-btn {
                background: none;
                color: $blackText;
                font-size: 18px;
                padding-inline: 0; }
            .confirmDelete-btn {
                background: $alertErrorText;
                color: white;
                font-size: 16px;
                padding-inline: 3.5rem;
                min-width: 180px; } }
        & > div:last-child {
            margin-top: 50px !important; } } }

@media screen and ( max-width: 1680px ) {
    .white-backdrop-fixed {
        .confirmDelete-content-container {} } }

@media screen and ( max-width: 1366px ) {
    .white-backdrop-fixed {
        .confirmDelete-content-container {
            h2 {
                font-size: 1.3rem; } } } }

@media screen and ( max-width: 1024px ) {
    .white-backdrop-fixed {
        .confirmDelete-content-container {
            padding: 2rem 2rem;
            transform: translateX(0);
            h2 {
                font-size: 1.2rem; }
            .confirmDelete-btns {
                column-gap: 1rem;
                .confirmDelete-btn {
                    min-width: auto; } } } } }
