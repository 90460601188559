.onboarding-nav {
    height: 50%;
    width: 100%;
    z-index: 4;
    .pages-titles-list {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        list-style: none;
        margin: 0;
        margin-top: 2rem;
        padding-left: 0;
        li {
            padding: 3rem 0rem;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            .circle {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background-color: $navItems;
                margin-right: 1.5rem;
                position: relative;
                &::after {
                    content: "";
                    width: 1px;
                    height: 600%;
                    background-color: $navItems;
                    position: absolute;
                    top: 22px;
                    left: 46%; } }
            &:last-child {
                .circle {
                    &::after {
                        display: none; } } }
            a,
            span {
                font-size: 1.5rem;
                line-height: 1.77rem;
                font-weight: $bold;
                text-decoration: none;
                color: $navItems; }
            &.selected {
                .circle {
                    background-color: $blackText;
                    &::after {
                        background-color: $blackText; } }
                a,
                span {
                    color: $blackText; } } } } }

@media screen and ( max-width: 1367px ) {
    .onboarding-nav {
        padding: 0.8rem;
        .pages-titles-list {
            li {
                padding: 2rem 0rem;
                .circle {
                    width: 12px;
                    height: 12px;
                    &::after {
                        height: 500%;
                        top: 22px;
                        left: 45%; } }
                a,
                span {
                    font-size: 1rem;
                    line-height: 1.75rem; } } } } }

@media screen and ( max-width: 1281px ) {
    .onboarding-nav {
        .pages-titles-list {
            margin-top: 0;
            li {
                .circle {
                    width: 12px;
                    height: 12px;
                    &::after {
                        height: 500%;
                        top: 22px; } }
                a,
                span {
                    font-size: 1.2rem; } } } } }


@media screen and ( max-width: 1025px ) {
    .onboarding-nav {
        .pages-titles-list {
            li {
                padding: 1.5rem 0;
                .circle {
                    width: 12px;
                    height: 12px;
                    margin-right: 1rem;
                    &::after {
                        height: 400%;
                        top: 20px;
                        left: 44%; } }
                a,
                span {
                    font-size: 1.1rem; } } } } }

@media screen and ( max-width: 768px ) {
    .onboarding-nav {
        .pages-titles-list {
            padding: 0 1rem;
            li {
                padding: 0.8rem 0rem;
                .circle {
                    width: 10px;
                    height: 10px;
                    margin-right: 1rem;
                    &::after {
                        height: 330%;
                        top: 15px;
                        left: 44%; } }
                a,
                span {
                    font-size: 0.8rem; } } } } }
