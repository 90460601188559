.leaflet-container {
    width: 85vw;
    height: 90vh; }

.map-wrapper {
    border-radius: 0 14px 14px 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    .leaflet-control-zoom {
        &.leaflet-bar {
            &.leaflet-control {
                border-radius: 10px;
                .leaflet-control-zoom-in {
                    border-radius: 10px 10px 0 0; }
                .leaflet-control-zoom-out {
                    border-radius: 0 0 10px 10px; } } } }
    .leaflet-popup-content {
        margin: 1rem 0.5rem 0.5rem 0.5rem;
        width: 180px;
        .marker-popup-list {
            list-style: none;
            padding: 0;
            font-family: $main-font;
            font-size: 12px;
            .marker-popup-item {
                padding: 0.3rem 0.1rem 0;
                margin: 0;
                display: flex;
                &.title {
                    color: $customerBrand;
                    border: none;
                    border-bottom: 1px solid $grayMisc;
                    padding: 0; }
                svg {
                    margin-bottom: 0.2rem;
                    margin-right: 0.3rem;
                    color: $navItems; } } } } }
