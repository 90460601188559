#locationList,
#locationSingle,
#distributionCenter,
#deliveryPromise,
#deliveryTimes,
#finish,
#panel-m {
    height: 100vh;
    .row {
        padding: 0;
        .content-wrapper {
            padding: 4rem 5rem 0;
            background-color: rgba(255, 255, 255, 0.5);
            // background-color: $lightText
            position: relative;
            .content-locations-list,
            .delivery-promise-list,
            .delivery-promise-time,
            .delivery-promise-rate {
                height: 65vh;
                overflow-y: auto;
                overflow-x: hidden;
                margin-bottom: 1.5rem;
                .unit {
                    display: flex;
                    justify-content: space-around;
                    align-items: flex-end;
                    border-radius: 15px;
                    padding: 1rem;
                    background-color: $backgroundLight;
                    &.single {
                        width: fit-content;
                        margin-bottom: 0.5rem; }
                    .select-wrapper {
                        margin-bottom: 0 !important;
                        padding-bottom: 0 !important; } } }
            .delivery-promise-list {
                height: 50vh;
                margin-bottom: 0.5rem; }
            .delivery-promise-rate {
                #rate-wrapper {
                    display: grid;
                    grid-template-columns: 0.2fr 0.15fr 1fr;
                    align-items: center;
                    margin-top: 2rem;
                    height: 50px;
                    div {
                        &:nth-child(1) {
                            margin-bottom: 0!important; }
                        &:nth-child(2) {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-top: 0; } }
                    small {
                        margin-left: 1rem; } }
                .promo-phrase-shop,
                .feeFixed-phrase-shop {
                    width: fit-content;
                    display: grid;
                    grid-template-columns: 1fr 0.5fr;
                    align-items: center;
                    text-align: center;
                    margin-left: 0.5rem;
                    margin-bottom: 1rem;
                    span {
                        padding: 0 10px; }
                    div {
                        display: flex;
                        align-items: center;
                        input {
                            width: 100px; } } }
                .promo-phrase-shop,
                .promo-phrase {
                    width: 75%;
                    display: grid;
                    grid-template-columns: auto 0.5fr auto auto;
                    align-items: center;
                    text-align: center;
                    margin-left: 1rem;
                    span {
                        margin-bottom: 0;
                        padding: 0 10px; }
                    label {
                        &:nth-child(1) {
                            display: none; } }
                    .select-wrapper {
                        padding: 0; }
                    .radios-wrapper {
                        width: fit-content;
                        border-radius: 18px;
                        display: flex;
                        justify-content: space-between;
                        border: 1px solid $customerBrand; } }
                .promo-phrase-shop {
                    width: fit-content;
                    margin-bottom: 0; } }
            .delivery-type {
                height: fit-content;
                h3 {
                    margin-bottom: 1.3rem; }
                #subtype-wrapper {
                    padding-left: 2rem; }
                #rate-wrapper {
                    .fee-phrase,
                    .feeFixed-phrase {
                        width: 60%;
                        display: grid;
                        grid-template-columns: auto 0.5fr auto auto;
                        align-items: center;
                        text-align: center;
                        margin-left: 1rem;
                        span {
                            margin-bottom: 0; }
                        label {
                            &:nth-child(1) {
                                display: none; } }
                        .select-wrapper {
                            padding: 0; }
                        .radios-wrapper {
                            width: fit-content;
                            border-radius: 18px;
                            display: flex;
                            justify-content: space-between;
                            border: 1px solid $customerBrand; } }
                    .feeFixed-phrase {
                        width: 35%;
                        grid-template-columns: 1fr 0.5fr;
                        margin-left: 0.5rem;
                        margin-bottom: 1rem; } } }
            .delivery-promise-time {
                height: fit-content;
                margin-bottom: 3rem;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start; }
            .form-grid-two-columns {
                padding: 0.5rem 0rem;
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 2rem;
                .double-input-col {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-gap: 1rem; } }
            .bottomPage {
                position: absolute;
                bottom: 2rem;
                right: 2.5rem; }
            .map-container {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 52vh;
                img {
                    width: 40%; } }
            .doubleBtn {
                width: 93%;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 1.5rem;
                a {
                    text-decoration: none;
                    img {
                        margin-right: 1rem; } } }
            .OutOfZone-wrapper,
            .finish-wrapper {
                height: 95%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                text-align: center;
                padding: 2rem 5rem 0;
                .next-steps {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    background-color: $backgroundLight;
                    border-radius: 0 20px 20px 20px;
                    margin: 5rem auto 0;
                    padding: 2rem 6rem;
                    text-align: center;
                    width: 100%;
                    p {
                        margin-bottom: 2rem; } }
                .finish-illustration-text {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    .illustration-wrapper {
                        img {
                            width: 25%; } }
                    .next-steps {
                        margin-top: 4rem;
                        p {
                            margin-bottom: 0; } } } }
            .OutOfZone-wrapper {
                height: 50vh;
                display: flex;
                justify-content: center;
                .aboveMsg-illustration {
                    width: 13%;
                    margin: 0 auto; }
                .next-steps {
                    padding: 1.5rem;
                    margin-top: 1.5rem;
                    p {
                        margin-bottom: 0;
                        &:nth-child(1) {
                            margin-bottom: 0.5rem; } } } } } } }

@media screen and ( max-width: 1367px ) {
    #locationList,
    #locationSingle,
    #distributionCenter,
    #deliveryPromise,
    #deliveryTimes,
    #finish {
        overflow: hidden;
        .row {
            .content-wrapper {
                padding: 3rem 4rem 1rem;
                .content-locations-list {
                    height: 55vh; }
                .delivery-promise-list {
                    height: 42vh; }
                .delivery-promise-rate {
                    height: fit-content;
                    margin-bottom: 0;
                    #rate-wrapper {
                        margin-top: 1rem;
                        height: 40px; }
                    .feeFixed-phrase-shop {
                        width: 35%;
                        margin: 0.5rem 0 0.5rem 0.5rem;
                        grid-template-columns: 1fr 0.6fr; } }
                .form-grid-two-columns {
                    padding: 0.5rem 0;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-gap: 1rem; }
                .finish-wrapper {
                    padding: 0 6rem;
                    .next-steps {
                        padding: 1rem; } }
                .map-container {
                    height: 40vh;
                    img {
                        width: 40%; } }
                .OutOfZone-wrapper {
                    height: fit-content;
                    .aboveMsg-illustration {
                        width: 18%;
                        margin: 0 auto; }
                    .next-steps {
                        margin-top: 2rem;
                        padding: 1.5rem; } } } } }
    #finish {
        .row {
            .content-wrapper {
                padding: 3rem; } } } }

@media screen and ( max-width: 1127px ) {
    #locationList,
    #locationSingle,
    #distributionCenter,
    #deliveryPromise,
    #deliveryTimes,
    #finish {
        .row {
            .content-wrapper {
                .OutOfZone-wrapper {
                    height: fit-content;
                    .aboveMsg-illustration {
                        width: 10%;
                        margin: 0 auto; }
                    .next-steps {
                        margin-top: 1rem;
                        padding: 1.5rem; } } } } } }

@media screen and ( max-width: 1025px ) {
    #locationList,
    #locationSingle,
    #distributionCenter,
    #deliveryPromise,
    #deliveryTimes,
    #finish {
        .row {
            .content-wrapper {
                padding: 2.5rem 3rem 1rem;
                .content-locations-list {
                    height: 60vh; }
                .delivery-promise-list {
                    height: 40vh; }
                .delivery-promise-rate {
                    .feeFixed-phrase-shop {
                        margin: 0;
                        width: 40%; } }
                .OutOfZone-wrapper,
                .finish-wrapper {
                    padding: 0 3rem;
                    .next-steps {
                        padding: 1rem 2rem;
                        margin: 2rem auto 0 !important; } }
                .doubleBtn {
                    width: 91%; }
                .form-grid-two-columns {
                    &.list {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-start; } }
                .OutOfZone-wrapper {
                    .aboveMsg-illustration {
                        width: 18%;
                        margin: 2rem auto 0; }
                    .next-steps {
                        margin-top: 2rem;
                        padding: 1rem; } } } } } }

@media screen and ( max-width: 768px ) {
    #locationList,
    #locationSingle,
    #distributionCenter,
    #deliveryPromise,
    #deliveryTimes,
    #finish {
        .row {
            .content-wrapper {
                padding: 1.5rem 2rem;
                .content-locations-list {
                    height: 40vh; }
                .delivery-promise-list {
                    height: 28vh;
                    .unit {
                        padding: 0.5rem; }
                    #rate-wrapper {
                        height: 30px;
                        small {
                            font-size: 0.7rem; }
                        input {
                            &.form-control {
                                padding: 0.3rem 0.5rem; } } } }
                .delivery-promise-rate {
                    .feeFixed-phrase-shop {
                        width: 45%;
                        margin: 0.5rem 0 0.5rem 0.5rem; } }
                .doubleBtn {
                    width: 87%; }
                .form-grid-two-columns {
                    &.list {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-start; } }
                .next-steps {
                    border-radius: 18px;
                    padding: 1rem;
                    margin: 1.5rem auto; }
                .OutOfZone-wrapper {
                    .next-steps {
                        margin: 1rem auto 0; } } } } } }
