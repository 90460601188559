.form-control,
.form-select {
    border-radius: 7px;
    font-size: 1.125rem;
    &.padding-lg {
        padding: 1.5rem 1rem; } }

.select-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 1rem 0.5rem 0; }

.icon-parent {
    position: relative;
    .icon-child {
        position: absolute;
        top: -30px;
        right: 10px;
        cursor: pointer;
        z-index: 3; } }

.form-label {
    font-size: 1.125rem;
    &.label-bold {
        font-weight: $bold;
        color: $blackText; } }

.form-check-label,
.form-duration-label {
    font-size: 1rem;
    &.label-bold {
        font-weight: $bold;
        color: $blackText; } }

.form-check-input {
    border-color: $customerBrand;
    align-self: center;
    &:checked {
        background-color: $customerBrand;
        border-color: $customerBrand; } }

.tooltip-parent {
    position: relative;
    width: 100%;
    .tooltip-child {
        position: absolute;
        bottom: 35px;
        right: -88px;
        width: 210px;
        text-align: center;
        border-radius: 6px;
        background-color: $backgroundDark;
        padding: 0.5rem;
        color: white;
        font-size: 0.75rem;
        opacity: 0;
        transition: all 0.2s ease-in-out;
        z-index: -1;
        .tooltip-list {
            text-align: left;
            padding: 0 0.5rem 0.5rem;
            ul {
                list-style: none;
                padding-left: 0;
                margin-bottom: 0; } }
        &.display {
            opacity: 1;
            z-index: 2; }
        &::after {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 95px;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-top: 12px solid $backgroundDark;
            z-index: -1; } } }

.from-to-wrapper {
    padding: 0 1rem 0 1rem;
    &.shopify,
    &.vtex {
        display: flex;
        flex-direction: column;
        div {
            display: grid;
            grid-template-columns: 0.4fr 0.2fr 0.4fr 0.4fr;
            margin-top: 1rem;
            align-items: baseline;
            text-align: center; } }
    &.tiendanube {
        display: flex;
        flex-direction: row;
        justify-content: flex-center;
        align-items: center;
        width: fit-content;
        div {
            display: grid;
            grid-template-columns: 0.4fr 0.2fr 0.4fr 0.4fr;
            align-items: baseline;
            text-align: center;
            margin-left: 1rem;
            margin-top: 0 !important; }
        h4 {
            margin-bottom: 0;
            text-align: left; }
        small {
            width: fit-content;
            display: inline-block;
            margin: 0 auto; } } }

textarea {
    resize: none; }

.is-valid {
  background-image: none !important; }

.is-invalid {
  background-image: none !important; }

@media screen and ( max-width: 1367px ) {
    .form-control,
    .form-select {
        font-size: 0.75rem;
        &.padding-lg {
            padding: 0.875rem; } }

    .select-wrapper {
        padding: 0; }

    .icon-parent {
        .icon-child {
            width: 17px;
            top: -25px; } }

    .form-label {
        font-size: 0.75rem;
        margin-bottom: 0.3rem; }

    .form-check-label,
    .form-duration-label {
        font-size: 0.875rem; }

    .tooltip-parent {
        .tooltip-child {
            width: 250px;
            font-size: 0.688rem;
            &::after {
                bottom: -10px;
                left: 135px; } } }
    .from-to-wrapper {
        padding: 0;
        &.tiendanube {
            div {
                grid-template-columns: 0.4fr 0.2fr 0.4fr 0.5fr; } } } }

@media screen and ( max-width: 1025px ) {
    .form-control,
    .form-select {
        font-size: 0.75rem;
        &.padding-lg {
            padding: 0.5rem; } }

    .select-wrapper {
        padding: 0 1rem 0 0; }

    .form-label,
    .form-check-label,
    .form-duration-label {
        font-size: 0.75rem;
        margin-bottom: 0.3rem; }

    .from-to-wrapper {
        grid-template-columns: 1.5fr 0.4fr 0.2fr 0.4fr 0.3fr;
        padding-right: 0.5rem;
        small {
            font-size: 0.75rem; } } }

@media screen and ( max-width: 768px ) {
    .form-control,
    .form-select {
        font-size: 0.6rem; }

    .select-wrapper {
        padding: 0 1rem 0 0; }

    .icon-parent {
        .icon-child {
            width: 15px;
            top: -23px; } }

    .form-label,
    .form-check-label,
    .form-duration-label {
        font-size: 0.6rem; }

    .form-check-label {
        font-size: 0.7rem; }

    .from-to-wrapper {
        padding: 0;
        small {
            font-size: 0.6rem; } } }
