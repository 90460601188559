.white-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: rgba(45, 45, 45, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    .flags-content-container {
        background-color: $backgroundLight;
        border-radius: 10px;
        padding: 2rem 5rem 5rem;
        position: relative;
        .closeButton {
            border: none;
            background: none;
            position: absolute;
            right: 20px;
            top: 15px;
            font-weight: $bold; }
        h2 {
            font-size: 1.5rem;
            font-weight: $bold;
            text-align: center; }
        .flags-list {
            list-style: none;
            margin: 0;
            margin-top: 4rem;
            padding: 0;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 4rem;
            .flags-item {
                a {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-decoration: none;
                    color: $blackText;
                    img {
                        width: 52px;
                        margin-bottom: 0.5rem; }
                    span {
                        font-weight: $medium; } } } } } }

@media screen and ( max-width: 1680px ) {
    .white-backdrop {
        .flags-content-container {
            padding: 2rem 6rem 5rem; } } }

@media screen and ( max-width: 1366px ) {
    .white-backdrop {
        .flags-content-container {
            padding: 2rem 5rem 3rem;
            h2 {
                font-size: 1.3rem; } } } }

@media screen and ( max-width: 1024px ) {
    .white-backdrop {
        .flags-content-container {
            padding: 2rem 4rem;
            h2 {
                font-size: 1rem; }
            span {
                font-size: 0.8rem; }
            .flags-list {
                margin-top: 3rem;
                grid-gap: 3rem; } } } }
