.nav-tabs {
    padding: 0rem 4rem;
    background-color: $backgroundLight;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0 14px 0 0;
    .nav-item {
        .nav-link {
            text-decoration: none;
            text-align: center;
            color: $blackText;
            padding: 0.6rem 1rem;
            font-size: 0.875rem;
            &.active {
                border: none;
                border-bottom: 3px solid $customerBrand;
                font-weight: $bold;
                background-color: transparent; } } } }

@media screen and ( max-width: 768px ) {
    .nav-tabs {
        padding: 0rem 2rem;
        .nav-item {
            .nav-link {
                font-size: 0.75rem; } } } }
