* {
  font-variant-ligatures: none; }

body {
    font-family: $main-font;
    color: $blackText;
    letter-spacing: -0.43px;
    .main-title,
    .finish-main-title {
        font-family: $main-font;
        margin-bottom: 1rem;
        font-weight: $semibold; }
    .finish-main-title {
        font-weight: $bold; }
    .subtitle,
    .finish-subtitle {
        font-weight: $regular; }
    .small-title,
    .finish-small-title {
        font-family: $main-font;
        font-weight: $semibold;
        margin-bottom: 1rem;
        width: 100%; }
    .finish-small-title {
        margin-bottom: 0;
        font-family: $main-font;
        font-weight: $regular; }
    .medium-text {
        font-weight: $regular; }
    .small-text {
        font-weight: $medium;
        padding: 0 1.5rem;
        width: 100%; }
    .step-title {
        font-family: $main-font;
        font-weight: $bold;
        margin-bottom: 1rem; }
    .step-subtitle {
        font-weight: $regular; }
    .time-detail,
    .invalid-msg {
        font-size: 0.875rem;
        line-height: 1rem;
        display: inline-block;
        width: 100%;
        text-align: right; }
    .outOfZone,
    .invalid-msg {
        color: $customerBrand; }
    .invalid-msg {
        text-align: left;
        font-weight: $medium; } }

@media screen and ( max-width: 1921px ) {
    body {
        .main-title,
        .finish-main-title {
            font-size: 2rem; }
        .subtitle,
        .finish-subtitle {
            font-size: 1.5rem;
            line-height: 1.8rem; }
        .small-title {
            font-size: 2rem; }
        .finish-small-title {
            font-size: 1.3rem; }
        .small-text {
            font-size: 1.3rem;
            line-height: 1.6rem;
            padding: 0 8rem; }
        .step-title {
            font-size: 2rem; }
        .step-subtitle {
            font-size: 1.5rem; }
        .outOfZone {
            font-size: 1.5rem; } } }

@media screen and ( max-width: 1441px ) {
    body {
        .main-title,
        .finish-main-title {
            font-size: 1.5rem; }
        .subtitle,
        .finish-subtitle {
            font-size: 1.125rem;
            line-height: 1.4; }
        .small-title {
            font-size: 1.5rem; }
        .finish-small-title {
            font-size: 1.1rem; }
        .small-text {
            font-size: 1rem;
            line-height: 1.3rem;
            padding: 0 8rem; }
        .step-title {
            font-size: 1.5rem;
            line-height: 1.829rem;
            margin-bottom: 0.7rem; }
        .step-subtitle {
            font-size: 1.125rem;
            line-height: 1.371rem; } } }

@media screen and ( max-width: 1367px ) {
    body {
        .main-title,
        .finish-main-title {
            font-size: 1.5rem;
            margin-bottom: 0; }
        .subtitle,
        .finish-subtitle {
            font-size: 1rem;
            line-height: 1.438rem; }
        .small-title,
        .finish-small-title {
            font-size: 1.25rem;
            line-height: 1.563rem;
            margin-bottom: 0.5rem; }
        .finish-small-title {
            font-size: 1.1rem;
            line-height: 1.563rem;
            margin-bottom: 0; }
        .medium-text {
            font-size: 0.875rem;
            line-height: 1.063rem; }
        .small-text {
            font-size: 0.75rem;
            line-height: 0.938rem;
            padding: 0 9.5rem;
            margin-bottom: 0; }
        .step-title {
            font-size: 1.5rem;
            line-height: 1.371rem;
            margin-bottom: 0.5rem; }
        .step-subtitle {
            font-size: 1rem;
            line-height: 1.3rem; }
        .method-detail {
            font-size: 0.875rem;
            line-height: 1rem; }
        .time-detail,
        .invalid-msg {
            font-size: 0.75rem;
            line-height: 1rem; }
        .outOfZone {
            font-size: 1rem; } } }

@media screen and ( max-width: 1281px ) {
    body {
        .main-title,
        .finish-main-title {
            font-size: 1.2rem; }
        .small-text {
            padding: 0 5rem; }
        .step-title {
            font-size: 1.2rem; } } }

@media screen and ( max-width: 1025px ) {
    body {
        .main-title,
        .finish-main-title {
            font-size: 1.1rem; }
        .subtitle,
        .finish-subtitle {
            font-size: 0.9rem;
            line-height: 1.2rem; }
        .small-title,
        .finish-small-title {
            font-size: 1rem;
            line-height: 1.3rem; }
        .small-text {
            padding: 0 2.5rem; }
        .step-title {
            font-size: 1.1rem; }
        .step-subtitle {
            font-size: 0.8rem; } } }

@media screen and ( max-width: 768px ) {
    body {
        .main-title,
        .finish-main-title {
            font-size: 0.9rem; }
        .subtitle,
        .finish-subtitle {
            font-size: 0.7rem;
            line-height: 0.9rem; }
        .small-title,
        .finish-small-title {
            font-size: 0.8rem;
            line-height: 1.1rem; }
        .small-text {
            font-size: 0.7rem;
            padding: 0 3rem; }
        .step-title {
            font-size: 0.875rem;
            margin-bottom: 0; }
        .step-subtitle {
            font-size: 0.7rem;
            line-height: 1rem; } } }

.mt-0 {
    margin-top: 0 !important; }
.mb-0 {
    margin-bottom: 0 !important; }
