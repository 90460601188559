.locationItem-grid {
    width: 100%;
    padding: 1rem;
    display: grid;
    grid-template-columns: 1.3fr 1.7fr 0.7fr;
    align-items: center;
    text-align: left;
    background-color: $backgroundLight;
    border-radius: 15px;
    .form-check {
        &.form-switch {
            display: grid;
            grid-template-columns: auto 1fr;
            justify-items: start;
            align-items: center;
            label {
                margin-left: 0.5rem;
                margin-bottom: 0; } } }
    .address-wrapper {
        text-align: center;
        small {
            font-size: 1rem;
            font-weight: $semibold;
            &.success {
                color: $alertSuccessText; } } }
    a {
        text-align: right;
        font-weight: $semibold;
        text-decoration: none;
        color: $blackText; }
    &.danger {
        background-color: $backgroundError;
        border-radius: 15px;
        .noSwitch {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            img {
                margin-right: 1rem; }
            h4 {
                width: 100%;
                text-align: left; } }
        h4,
        h3 {
            color: $alertErrorText;
            margin-bottom: 0; }
        a {
            text-align: right;
            color: $alertErrorText; } }
    &.warning {
        grid-template-columns: 1fr 1.7fr 0.7fr;
        background-color: $warningSuccessBackground;
        border-radius: 15px;
        .noSwitch {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            svg {
                margin-right: 1rem;
                color: $warningSuccessText; }
            h4 {
                width: 100%;
                text-align: left; } }
        h3 {
            margin-bottom: 0; }
        h4,
        small {
            margin-bottom: 0;
            color: $warningSuccessText; }
        a {
            text-align: right;
            color: $warningSuccessText; } } }

.form-check {
    &.form-switch {
        input {
            border: 1px solid $blackText;
            &:checked {
                border: 1px solid $customerBrand;
                background-color: $customerBrand; } } } }


@media screen and ( max-width: 1367px ) {
    .locationItem-grid {
        grid-template-columns: 1fr 1fr 0.8fr;
        .address-wrapper {
            h3 {
                font-size: 1rem;
                margin-bottom: 0; }
            small {
                font-size: 0.875rem; } } } }

@media screen and ( max-width: 1025px ) {
    .locationItem-grid {
        grid-template-columns: 1fr 1fr 0.8fr;
        padding: 0.7rem;
        border-radius: 10px;
        .address-wrapper {
            small {
                font-size: 0.75rem; }
            h3 {
                font-size: 0.75rem; } }
        .address-wrapper {}
        &.danger {
            border-radius: 10px; } } }

@media screen and ( max-width: 768px ) {
    .locationItem-grid {
        .form-check {
            &.form-switch {
                label {
                    margin-left: 0.5rem; } } }
        &.warning {
            grid-template-columns: 0.8fr 2fr 0.7fr; } } }
