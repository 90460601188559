.stepper-container {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: linear-gradient(217deg, rgba(243, 243, 243, 0.9), rgba(243, 243, 243, 0.7));
    height: 100vh;
    border-radius: 0 50px 50px 0;
    padding: 4rem 3rem 0;
    .corner-background {
        position: absolute;
        bottom: 0;
        left: 0; } }

.logo-container {
    padding: 3rem 0 0 4rem;
    padding: 0;
    margin-left: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
        max-width: 40%;
        margin-right: 0.5rem;
        &:nth-child(2) {
            border-left: 2px solid #cccccc; }
        &.shopify {
            max-width: 41%;
            padding-left: 1.1rem; }
        &.tiendanube {
            max-width: 51%;
            padding-left: 0.9rem; }
        &.vtex {
            max-width: 33%;
            padding-left: 1.1rem; } } }

@media screen and ( max-width: 1367px ) {
    .stepper-container {
        padding: 3rem 3rem 0; }
    .logo-container {
        img {
            &.shopify {
                max-width: 41%;
                padding-left: 1.1rem; }
            &.tiendanube {
                max-width: 51%;
                padding-left: 1rem; }
            &.vtex {
                max-width: 32%;
                padding-left: 0.8rem; } } } }

@media screen and ( max-width: 1281px ) {
    .stepper-container {
        padding: 2rem 3rem 0; } }

@media screen and ( max-width: 768px ) {
    .stepper-container {
        padding: 2rem 1.5rem 0;
        .corner-background {
            position: absolute;
            bottom: -50px;
            left: 0; } }
    .logo-container {
        img {
            max-width: 45%; } } }
