.dropdown {
    position: relative;
    .dropdown-menu {
        &.show {
            position: absolute;
            inset: 0px auto auto 0px;
            margin: 0px;
            transform: translate(-24px, 30px) !important;
            .dropdown-item {
                font-size: 0.75rem; } } } }

@media (max-width: 1367px) {
    .dropdown {
        .dropdown-menu {
            &.show {
                transform: translate(-28px, 25px) !important; } } } }
