#panel-m {
    height: 100vh;
    background-color: $backgroundPanel;
    position: relative;
    overflow: hidden;
    .corner-background {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0; }
    .row {
        padding: 0 1rem;
        .col {
            .logo-container {
                padding: 0.5rem 0;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                img {
                    max-width: 8%;
                    margin-right: 0.5rem;
                    &:nth-child(2) {
                        border-left: 2px solid #cccccc; }
                    &.shopify {
                        max-width: 41%;
                        padding-left: 1.1rem; }
                    &.tiendanube {
                        max-width: 12%;
                        padding-left: 0.9rem; }
                    &.vtex {
                        max-width: 6%;
                        padding-left: 0.8rem; } } } }
        &.row-grid {
            height: 87vh;
            .main-nav {
                z-index: 1;
                padding: 2rem 0 0 0;
                background-color: $backgroundLight;
                border-right: 1px solid $grayMisc;
                border-radius: 14px 0 0 14px;
                height: 100%; }
            .content-wrapper {
                padding: 0;
                border-radius: 0 14px 14px 0;
                height: 100%;
                z-index: 1;
                .grid-container,
                #locationsConfig,
                #deliveryConfig,
                #distributionCenterConfig,
                #dashboardMetrics,
                #settings,
                #account {
                    background-color: $lightText;
                    border-radius: 0 14px 14px 0;
                    height: 100%; }
                #locationsConfig,
                #locationSingleConfig,
                #deliveryConfig,
                #distributionCenterConfig,
                #dashboardMetrics,
                #settings,
                #account {
                    padding: 2rem 2rem 3rem;
                    .content-locations-list {
                        height: 52vh; }
                    .map-container {
                        height: 40vh; }
                    .delivery-promise-list {
                        height: 48vh; }
                    .delivery-promise-rate {
                        height: 65vh;
                        padding-left: 0.35rem;
                        #rate-wrapper {
                            margin-top: 1rem;
                            height: 40px; } }
                    .dateFilter-wrapper {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        box-shadow: 0px 5px 15px $backgroundLight;
                        padding: 1rem;
                        .filter-container {
                            width: fit-content;
                            margin-left: 1rem; } }
                    .card-graph {
                        box-shadow: 0px 5px 15px $backgroundLight;
                        border: none;
                        height: 100%;
                        padding: 1rem;
                        .card-header {
                            background: none;
                            border: none;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 1rem; } }
                    .radio-setting {
                        div {
                            &:nth-child(1) {
                                margin-bottom: 0!important;
                                font-weight: $medium; } }
                        p {
                            margin-left: 1rem; } }
                    .single-loc-form {
                        width: 60%; } }
                #dashboardMetrics {
                    overflow-y: auto;
                    overflow-x: hidden; }
                #account {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    #account-content {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        .warningBox {
                            background-color: $backgroundError;
                            color: $alertErrorText;
                            padding-block: 2.5rem;
                            font-size: 22px;
                            font-weight: 600;
                            text-align: center;
                            border-radius: 10px;
                            p {
                                max-width: 60ch;
                                padding: 0; } }
                        .deleteBtn {
                            font-size: 18px;
                            background-color: $alertErrorText; }
                        & > div:last-child {
                            padding-block: 5rem 2rem; } } } } } } }

.refresh {
    background: none;
    border: none;
    color: $grayMisc;
    z-index: 3;
    img {
        width: 80%; } }
.empty-msg {
    position: absolute;
    z-index: 90;
    left: 33%;
    top: 25%;
    background-color: $backgroundLight;
    border-radius: 17px;
    margin: 10% auto 1rem;
    padding: 1rem 2rem;
    text-align: center;
    width: fit-content;
    p {
        font-weight: $bold; } }


@media screen and ( max-width: 1367px ) {
    #panel-m {
        overflow: hidden;
        .row {
            &.row-grid {
                .content-wrapper {
                    #locationsConfig,
                    #locationSingleConfig,
                    #deliveryConfig,
                    #distributionCenterConfig,
                    #dashboardMetrics {
                        padding: 2rem;
                        .content-locations-list {
                            height: 50vh; }
                        .map-container {
                            height: 30vh;
                            img {
                                width: 25%; } }
                        .delivery-promise-list {
                            height: 40vh; }
                        .delivery-promise-rate {
                            height: 65vh;
                            #rate-wrapper {
                                margin-top: 1rem;
                                height: 30px; } } } } } } } }

@media screen and ( max-width: 1126px ) {
    #panel-m {
        .row {
            &.row-grid {
                .content-wrapper {
                    #locationSingleConfig {
                        .single-loc-form {
                            width: 60%; } }
                    #deliveryConfig {
                        .delivery-promise-rate {
                                height: 70vh; } } } } } } }

@media screen and ( max-width: 1025px ) {
    #panel-m {
        .row {
            padding: 0 2rem;
            .col {
                .logo-container {
                    padding: 0.3rem 0;
                    img {
                        max-width: 10%;
                        &.tiendanube {
                            max-width: 14%;
                            padding-left: 0.7rem; }
                        &.vtex {
                            max-width: 8%;
                            padding-left: 0.8rem; } } } }
            &.row-grid {
                .content-wrapper {
                    #locationsConfig,
                    #locationSingleConfig,
                    #deliveryConfig,
                    #distributionCenterConfig,
                    #dashboardMetrics {
                        padding: 1rem;
                        .map-container {
                            height: 30vh;
                            img {
                                width: 25%; } }
                        .delivery-promise-list {
                            height: 35vh; }
                        .delivery-promise-rate {
                            height: 80vh; } } } } } } }

@media screen and ( max-width: 768px ) {
    #panel-m {
        .row {
            padding: 0 1rem; } } }
