.swal-container-error,
.swal-container-success {
    font-family: $main-font;
    width: 60vw;
    background-color: $backgroundError;
    border-radius: 15px;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0%;
    right: 0%;
    margin-top: 5px;
    margin-right: 25px;
    .swal-icon {
        width: 26px;
        height: 27px;
        margin-right: 20px; }
    .swal-text {
        color: $alertErrorText;
        text-align: left;
        font-size: 18px;
        line-height: 21.94px; } }

.swal-container-success {
    background-color: $alertSuccessBackground;
    .swal-text {
        color: $alertSuccessText; } }


@media screen and ( max-width: 1025px ) {
    .swal-container-error,
    .swal-container-success {
        padding: 0.5rem;
        .swal-text {
            font-size: 14px; } } }

@media screen and ( max-width: 768px ) {
    .swal-container-error,
    .swal-container-success {
        padding: 0.5rem;
        .swal-text {
            font-size: 12px; } } }
