#welcome {
    height: 100vh;
    position: relative;
    .row {
        height: 100%;
        .col {
            padding: 0 4rem;
            display: flex;
            flex-direction: column;
            justify-content: center; }
        .welcome-grid {
            display: flex;
            justify-content: space-around;
            align-items: center;
            background-color: $backgroundLight;
            border-radius: 0 50px 0 0;
            .welcome-text {
                h1 {
                    margin-bottom: 1.5rem; } } }
        .form-col {
            .form-wrapper {
                border-radius: 14px;
                width: 70%;
                margin: 0 auto;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                form {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin: 0 auto;
                    padding: 0 5rem;
                    text-align: left;
                    margin: 2rem 0 1.5rem 0;
                    .select-wrapper {
                        padding: 0;
                        select {
                            padding: 1rem; } }
                    .loginButton {
                        margin-top: 1rem; } } }
            .type-select-wrapper {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                row-gap: 40px;
                h2 {}
                .type-buttons {
                    flex: 1;
                    width: 100%;
                    max-width: 730px;
                    display: flex;
                    flex-direction: column;
                    row-gap: 40px;
                    button {
                        background: $backgroundLight;
                        border: 2px solid $backgroundLight;
                        border-radius: 10px;
                        color: $blackText;
                        text-decoration: none;
                        font-weight: bold;
                        padding: 3rem 1rem;
                        transition: 250ms ease;
                        position: relative;
                        &:hover,
                        &:focus {
                            border-color: $customerBrand; }
                        &.type_selected {
                            color: $customerBrand;
                            border-color: $customerBrand; }
                        .type-check_selected {
                            position: absolute;
                            top: -.75rem;
                            right: -.75rem;
                            font-size: 2rem;
                            border-radius: 50%;
                            background: #fff;
                            box-shadow: 0 0 0 2px #fff;
                            opacity: 0; } } } } } }

    #postalcode-selector {
        height: 4rem;
        position: relative;
        top: 1rem;
        div {
            &.d-flex {
                margin-top: .25rem;
                gap: 3rem; } }
        label {
            &.title-postalcode {
                font-size: .9rem;
                div {
                    cursor: pointer;
                    font-size: .9rem !important; }
                @media screen and ( min-height: 800px) {
                    font-size: 1.1rem !important; } } } } }
.illustration-wrapper {
    width: 100%;
    img {
        max-width: 100%; } }

@media screen and ( max-width: 1367px ) {
    #welcome {
        overflow: hidden;
        .row {
            .col {}
            .welcome-grid {
                .welcome-text {
                    h1 {
                        margin-bottom: 1rem; } } }
            .form-col {
                .form-wrapper {
                    form {
                        margin: 0.5rem 0 0rem 0;
                        padding: 0 6rem; } } } } } }

@media screen and ( max-width: 1281px ) {
    #welcome {
        overflow: hidden;
        .row {
            .col {
                padding: 0 3rem; }
            .form-col {
                .form-wrapper {
                    form {
                        padding: 0 4rem; } } } } } }

@media screen and ( max-width: 1025px ) {
    #welcome {
        overflow: hidden;
        .row {
            .col {
                padding: 0 2rem; }
            .welcome-grid {
                .welcome-text {
                    h1 {
                        margin-bottom: 1rem; } } }
            .form-col {
                .form-wrapper {
                    form {
                        padding: 0 3rem;
                        .loginButton {
                            margin-top: 1rem; } } } } } } }

@media screen and ( max-width: 768px ) {
    #welcome {
        .row {
            .col {
                padding: 0 1rem; }
            .form-col {
                .form-wrapper {
                    form {
                        padding: 0 3rem;
                        margin: 0.8rem 0 0;
                        .select-wrapper {
                            select {
                                padding: 0.5rem; } } } } } } } }
